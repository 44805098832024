import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'util-quality-check',
  templateUrl: './quality-check.component.html',
  styleUrls: ['./quality-check.component.scss']
})
export class QualityCheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
