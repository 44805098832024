<ui5-card class="medium">
    <ui5-card-header slot="header" title-text="SFC at STATION_1" subtitle-text="SFC000000001">
    </ui5-card-header>
 <ui5-list separators="None" class="content-padding">
     <ui5-li-custom>
         <div class="item">
             <div class="item-content-begin">
                 <ui5-title level="H5" class="item-content-begin-title">Operation 123</ui5-title>
                 <ui5-label>ID234522566-D44</ui5-label>
             </div>
             <div class="item-content-end">
                 <span class="status-success">Finished</span>
             </div>
         </div>
     </ui5-li-custom>
     <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 124</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-success">Finished</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
    <ui5-li-custom>
        <div class="item">
            <div class="item-content-begin">
                <ui5-title level="H5" class="item-content-begin-title">Operation 125</ui5-title>
                <ui5-label>ID234522566-D44</ui5-label>
            </div>
            <div class="item-content-end">
                <span class="status-warning">Waiting</span>
            </div>
        </div>
    </ui5-li-custom>
 </ui5-list>
</ui5-card>