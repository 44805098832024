<div class="row">
    <div class="col-25">
        <util-routing></util-routing>
    </div>
    <div class="col-75">
        <ui5-tabcontainer class="full-width" show-overflow>
            <ui5-tab icon="activities" text="Animation">
                <util-animation></util-animation>
            </ui5-tab>
            <ui5-tab icon="activities" text="Image" selected>
                <util-image></util-image>
            </ui5-tab>
            <ui5-tab icon="add" text="3D Model">
                <util-model></util-model>
            </ui5-tab>
            <ui5-tab icon="calendar" text="Quality Check">
                <util-quality-check></util-quality-check>
            </ui5-tab>
        </ui5-tabcontainer>
    </div>
</div>