import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'util-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
