import { Component, OnInit } from '@angular/core';
import "@ui5/webcomponents/dist/Carousel";

@Component({
  selector: 'util-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
