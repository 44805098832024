import { Component, OnInit } from '@angular/core';
import "@ui5/webcomponents/dist/Card";
import "@ui5/webcomponents/dist/CardHeader";
import "@ui5/webcomponents/dist/List";

@Component({
  selector: 'util-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss']
})
export class RoutingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
