import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AnimationComponent } from './util/animation/animation.component';
import { ImageComponent } from './util/image/image.component';
import { ModelComponent } from './util/model/model.component';
import { QualityCheckComponent } from './util/quality-check/quality-check.component';
import { HeaderComponent } from './util/header/header.component';
import { RoutingComponent } from './util/routing/routing.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AnimationComponent,
    ImageComponent,
    ModelComponent,
    QualityCheckComponent,
    HeaderComponent,
    RoutingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
